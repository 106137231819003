import React from 'react'

import { navigate } from "gatsby"
import Link from 'gatsby-link'
import { Stack, Typography, Button } from '@mui/material';

import imgPlaystore from "../images/playstore.svg"

import translateManager from "../translations/translateManager"

import Layout from '../components/Layout'
import AppDetail from '../components/AppDetail'

import { listVersions } from "../data"

export default function haccp() {
    const item = listVersions.haccp;

    const haccpPlayStoreUrl = "https://play.google.com/store/apps/details?id=com.mycawan.haccp";

    return (
        <Layout>

{
                // commente pour ne pas mettre a dispo des clients dans un premier temps
            }

            <Typography style={{margin: "2em 0 .5em 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "3em"}} align="center" variant="string" component="div">{translateManager.getText("HACCP_HISTORY_TITLE")}</Typography>
            
            
            { // v2 
            }
            <Typography style={{margin: "2em 0 0 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "2em"}} align="center" variant="string" component="div">{translateManager.getText("HACCP_PLAYSTORE_TITLE")}</Typography>
            <Typography variant="string" style={{cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "1em"}} align="center" component="div">{translateManager.getText("HACCP_PLAYSTORE_SUBTITLE")}</Typography>
            <Typography variant="string" style={{cursor: "pointer", fontFamily: "Lexand Deca", fontSize: "1em"}} align="center" component="div">{translateManager.getText("HACCP_PLAYSTORE_TEXT")}</Typography>
            <Link to={haccpPlayStoreUrl} target="_blank"><div style={{textAlign: "center", padding: "1em"}}><img style={{cursor: "pointer", width: "48px"}} src={imgPlaystore}/></div></Link>

            { // v1.78
            }
            <Typography style={{margin: "2em 0 0 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "2em"}} align="center" variant="string" component="div">{translateManager.getText("HACCP_NEW_TITLE")}</Typography>
            <Typography variant="string" style={{margin: "0 0 .5em 0", cursor: "pointer", fontFamily: "Lexand Deca", fontSize: "0.9em"}} align="center" component="div">{translateManager.getText("HACCP_NEW_LINK_TEXT0")}</Typography>
            <Typography variant="string" style={{cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em"}} align="center" component="div" onClick={() => { navigate("/haccp2023")} }>{translateManager.getText("HACCP_NEW_LINK_TEXT1")}</Typography>
            <Typography variant="string" style={{ margin: "0.5em 0", cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em"}} align="center" component="div" onClick={() => { navigate("/haccp2023")} }>{translateManager.getText("HACCP_NEW_LINK_TEXT2")}</Typography>
            

            { // v1.77
            }
            <Typography style={{margin: "2em 0 0 0", fontFamily:"Source Serif Pro semi-bold", fontSize: "2em"}} align="center" variant="string" component="div">{translateManager.getText("HACCP_177_TITLE")}</Typography>
            <AppDetail appData={listVersions.haccp} nocard={true} />


            <Stack 
                style={{margin: "4em 0"}}
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">

                {/* <Button variant="outlined" size="small" onClick={() => { navigate("/")} }>{translateManager.getText("BACK_BTN")}</Button> */}
                <Typography variant="string" style={{margin: "4em 0 0 0", cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em"}} component="div" onClick={() => { navigate("/")} }>{translateManager.getText("BACK_BTN")}</Typography>

            </Stack>

        </Layout>
    )
}
